<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">
        ANBS Copyright &copy; {{ new Date().getFullYear() }}.
      </span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1">Powered by</span>
      <a href="javascript:">Apollo LDS App</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
