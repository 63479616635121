<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <div class="c-sidebar-brand-full" style="line-height: 3rem;">
        <img src="/img/logo.png" style="max-height: 48px" />
        <span style="font-size:1.3rem;" class="ml-2"
          >Apollo <strong>LDS</strong></span
        >
      </div>
      <img
        src="/img/logo.png"
        class="c-sidebar-brand-minimized"
        style="max-height: 48px"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="optionsNav()" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  methods: {
    optionsNav() {
      let nav = this.$options.nav;
      let authUser = this.$user.getters.authUser;
      let admin = this.$const.ROLES.Admin;

      nav.forEach((item) => {
        item._children = item._children.filter(
          (c) =>
            authUser &&
            authUser.userType &&
            (authUser.userType == admin ||
              !c.roles ||
              !c.roles.length ||
              c.roles.includes(authUser.userType))
        );
      });

      return nav;
    },
  },
};
</script>
