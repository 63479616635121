export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Báo cáo trúng thưởng",
        to: "/reward-report",
        icon: "cil-ChartPie",
        badge: {
          color: "primary",
          text: "NEW",
        },
        roles: ["admin"],
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["Khuyến mãi"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Cài trúng thưởng",
        to: "/condition",
        icon: "cil-Tags",
        roles: ["admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Mã dự thưởng",
        to: "/lucky-code",
        icon: "cil-Storage",
        roles: ["admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Giải thưởng",
        to: "/reward",
        icon: "cil-Gift",
        roles: ["admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Chương trình",
        to: "/lucky-program",
        icon: "cil-MobileLandscape",
        roles: ["admin"],
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["Cài đặt chung"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Cửa hàng",
        to: "/shop",
        icon: "cil-Star",
        roles: ["admin"],
      },
    ],
  },
];
